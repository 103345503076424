<template>
  <div class="auth-wrapper auth-v2">
    <div class="auth-inner">
      <v-row class="auth-row ma-0">
        <v-col
          lg="8"
          class="d-none d-lg-block position-relative overflow-hidden pa-0 ghd-base-color-black"
        >
          <div
            class="d-flex align-center justify-center h-full"
          >
            <!-- triangle bg -->
            <img
              height="auto"
              width="auto"
              :src="appLogo"
            />
          </div>
        </v-col>

        <v-col
          lg="4"
          class="d-flex align-center auth-bg pa-10 pb-0 ghd-base-color-white"
        >
          <v-row>
            <v-col
              cols="12"
              sm="8"
              md="6"
              lg="12"
              class="mx-auto"
            >
              <v-card
                flat
                class="ghd-base-color-white ghd-border-black"
              >
                <v-card-text>
                  <p class="text-2xl font-weight-semibold text--primary mb-2">
                    Modifica la tua password
                  </p>
                  <p class="mb-2">
                  </p>
                </v-card-text>

                <!-- login form -->
                <v-card-text>
                  <v-form
                    ref="resetPasswordForm"
                    @submit.prevent="handleSubmitForm"
                  >
                    <v-text-field
                      v-model="password"
                      outlined
                      label="Password"
                      placeholder="Password"
                      hide-details="auto"
                      class="mb-4"
                      :error-messages="errorMessages.password"
                      :rules="[validators.required]"
                      :type="isPasswordVisible ? 'text' : 'password'"
                      :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                      @click:append="isPasswordVisible = !isPasswordVisible"
                    ></v-text-field>

                    <v-text-field
                      v-model="passwordConfirmation"
                      outlined
                      label="Conferma password"
                      placeholder="Conferma password"
                      hide-details="auto"
                      class="mb-4"
                      type="password"
                      :error-messages="errorMessages.passwordConfirmation"
                      :rules="[validators.required]"
                    ></v-text-field>

                    <v-btn
                      block
                      color="primary"
                      type="submit"
                    >
                      Cambia password
                    </v-btn>
                  </v-form>
                </v-card-text>
                <v-card-actions class="d-flex justify-center align-center">
                  <router-link
                    :to="{name:'auth-login'}"
                    class="d-flex align-center text-sm"
                  >
                    <v-icon
                      size="24"
                      color="primary"
                    >
                      {{ icons.mdiChevronLeft }}
                    </v-icon>
                    <span>Torna al login</span>
                  </router-link>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mdiChevronLeft, mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import themeConfig from '@themeConfig'
import { useRouter } from '@core/utils'
import { ref, computed } from '@vue/composition-api'
import { required } from '@core/utils/validation'
import axios from '@axios'

// import getErrors from '/src/plugins/errors.js'
import store from '@/store'
import Vue from 'vue'

export default {
  setup() {
    const isPasswordVisible = ref(false)

    const errorMessages = computed(() => store.getters['global/getErrorMessages'])

    const password = ref('')

    const passwordConfirmation = ref('')

    const router = useRouter()

    const resetPasswordForm = ref(null)

    const handleSubmitForm = () => {
      const isFormValid = resetPasswordForm.value?.validate()

      if (!isFormValid) return

      axios.post('api/reset/password', {
        token: router.route.value.params.token,
        password: password.value,
        password_confirmation: passwordConfirmation.value,
      })
    }

    return {
      resetPasswordForm,
      isPasswordVisible,
      password,
      passwordConfirmation,
      errorMessages,

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      icons: {
        mdiChevronLeft,
        mdiEyeOutline,
        mdiEyeOffOutline,
      },

      validators: {
        required,
      },

      handleSubmitForm,
    }
  },
}
</script>

<style lang="scss">
  @import '@core/preset/preset/pages/auth.scss';
</style>
